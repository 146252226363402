import React from "react";
import { Container } from "basis";

import StyledPre from "./StyledPre";

const JsonView = ({ data }) => (
  <Container bg="grey.t05" padding="6" hasBreakpointWidth>
    <StyledPre>{JSON.stringify(data, null, 6)}</StyledPre>
  </Container>
);

export default JsonView;
