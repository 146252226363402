
export const CURRENCY = {
  AUD: "AUD",
  NZD: "NZD",
  EMPTY: "",
};

export const BRAND = {
  [CURRENCY.AUD]: "Latitude",
  [CURRENCY.NZD]: "Gem",
  [CURRENCY.EMPTY]: "Latitude",
};

export const QUERY_PARAM = {
  TOKEN: "token",
  MERCHANT_ID: "merchantId",
  TRANSACTION_ID: "transactionId",
};

export const TRANSACTION_STATUS = {
  SUBMITTED: "submitted",
  INITIATED: "initiated",
  INVALID: "invalid",
  APPROVED: "approved",
  APPROVED_NO_CALLBACK: "approved_no_callback",
  REJECTED: "rejected",
  REJECTED_FRAUD: "rejected_fraud",
  DECLINED: "declined",
  REJECTED_NO_CALLBACK: "rejected_no_callback",
  GATEWAY_ERROR: "gateway_error",
  CANCELLED: "cancelled",
};

export const TRANSACTION_TYPE = {
  SUBMITTED: "submitted",
  INITIATED: "initiated",
  SALE: "sale",
  AUTHORIZATION: "authorization",
  CAPTURE: "capture",
  REFUND: "refund",
  VOID: "void",
  CAPTURE_PARTIAL: "capture-partial",
  CANCELLED: "cancelled",
};

export const ALLOWED_PARTIAL_TRANSACTION_TYPES = [
  TRANSACTION_TYPE.REFUND,
  TRANSACTION_TYPE.VOID,
  TRANSACTION_TYPE.CAPTURE,
];

export const TRANSACTION_OUTCOME = {
  APPROVED: "Approved",
  REJECTED: "Rejected",
  AUTHORISED: "Authorised",
  PART_REFUNDED: "Part_Refunded",
  DECLINED: "Declined",
  QUOTE: "Quote",
  INVALID: "Invalid",
  VOID: "Void",
  REFUND: "Refunded",
  CAPTURE: "Captured",
  COMPLETED: "Completed",
  CANCELLED: "Cancelled",
  PENDING: "Pending",
};

export const TRANSACTION_RESULT = {
  ERROR: "error",
  PENDING: "pending",
  COMPLETED: "completed",
};

export const PLATFORM_TYPE = {
  DIRECT: "directintegration",
  MAGENTO2: "magento2",
  WOOCOMMERCE: "woocommerce",
  SHOPIFY: "shopify",
  INSTOREPOS: "instore-pos",
  INSTOREDIRECT: "instore-direct",
};

export const PLATFORMS = {
  DIRECT: {
    value: PLATFORM_TYPE.DIRECT,
    label: "Direct Integration",
  },
  MAGENTO2: {
    value: PLATFORM_TYPE.MAGENTO2,
    label: "Magento2",
  },
  WOOCOMMERCE: {
    value: PLATFORM_TYPE.WOOCOMMERCE,
    label: "WooCommerce",
  },
  Shopify: {
    value: PLATFORM_TYPE.SHOPIFY,
    label: "Shopify",
  },
};

export const PAYMENT_METHOD = {
  SALES_FINANCE: "sales-finance",
  SALES_FINANCE_POINTS: "sales-finance-points",
};

export const EVENTS = {
  LearnMore: "LearnMore",
  LearnMorePromotion: "LearnMorePromotion",
  Apply: "Apply",
  About: "About",
  MerchantLogin: "MerchantLogin",
  MerchantCreateOrder: "MerchantCreateOrder",
};

export const ACTION = {
  Click: "Click",
  Pageload: "Pageload",
};

export const COUNTRY = {
  AU: "AU",
  NZ: "NZ",
};

export const ALLOWED_COUNTRIES = [COUNTRY.AU, COUNTRY.NZ];

export const PAGES = {
  About: "applybuy-checkout-spa-about",
  Checkout: "applybuy-checkout-spa-checkout",
  MerchantLogin: "merchant-login",
  MerchantOrderForm: "merchant-order-form",
};

export const MERCHANT_PAGE_ROUTES = {
  Default: "/merchant/home",
  OrderDashboard: "/merchant/orders/dashboard",
  Reports: "/merchant/reports?dv=1",
  Login: "/merchant/login",
  Logout: "/merchant/logout",
  Create: "/merchant/orders/create",
  Summary: "/merchant/orders/summary",
  transactionDashboard: "/merchant/orders/transactiondashboard",
  SettlementSummary: "/merchant/statements/settlementsummary",
  ViewOrder: "/merchant/orders/review",
  Analytics: "/merchant/analytics",
  SelectMerchant: "/merchant/selectmerchant",
  TrainingGuides: "/merchant/trainingguides",
  ManageUsers: "/merchant/manageusers/dashboard"
};

export const CUSTOMER_PAGE_ROUTES = {
  Logout: "/wallet/logout",
};

export const POS_PAGE_ROUTES = {
  Summary: "/merchant/pos/orders/summary",
};

export const ENTITY = {
  Merchant: "merchant",
};

export const ENVIRONMENT = {
  PROD: "prod",
  TEST: "test",
  DEV: "dev",
};

export const GATEWAY_NAME = {
  LATITUDE_PAY: "latitudepay",
  LATITUDE_INTEREST_FREE: "lat-int-free",
  GENOA_PAY: "genoapay",
  GEM_INTEREST_FREE: "gem-int-free",
};

export const GATEWAY_TITLE = {
  [GATEWAY_NAME.LATITUDE_PAY]: "LatitudePay",
  [GATEWAY_NAME.LATITUDE_INTEREST_FREE]: "Latitude Interest Free",
  [GATEWAY_NAME.GENOA_PAY]: "Genoapay",
  [GATEWAY_NAME.GEM_INTEREST_FREE]: "Gem Interest Free",
};

export const GATEWAY_ICON = {
  [GATEWAY_NAME.LATITUDE_PAY]: "latitudePay",
  [GATEWAY_NAME.LATITUDE_INTEREST_FREE]: "latitudeInterestFree",
  [GATEWAY_NAME.GENOA_PAY]: "genoaPay",
  [GATEWAY_NAME.GEM_INTEREST_FREE]: "gemInterestFree",
};

export const MERCHANTID = {
  MOCK_MERCHANT: "mockmerchantid",
};

export const PROMO_SELECT_PLACEHOLDER = "Please select an Interest Free plan";

export const MERCHANT_SUPPORT_EMAIL_ADDRESS = {
  [GATEWAY_NAME.LATITUDE_PAY]: "integrationsupport@latitudefinancial.com",
  [GATEWAY_NAME.LATITUDE_INTEREST_FREE]:
    "applyandbuy.anz@latitudefinancial.com",
  [GATEWAY_NAME.GENOA_PAY]: "integrationsupport@latitudefinancial.com",
  [GATEWAY_NAME.GEM_INTEREST_FREE]: "applyandbuy.anz@latitudefinancial.com",
  fallback: "applyandbuy.anz@latitudefinancial.com",
};

export const CUSTOMER_SUPPORT_EMAIL_ADDRESS = {
  [GATEWAY_NAME.LATITUDE_PAY]: "help@latitudepay.com",
  [GATEWAY_NAME.LATITUDE_INTEREST_FREE]:
    "applyandbuy.anz@latitudefinancial.com",
  [GATEWAY_NAME.GENOA_PAY]: "help@genoapay.com",
  [GATEWAY_NAME.GEM_INTEREST_FREE]: "applyandbuy.anz@latitudefinancial.com",
  fallback: "applyandbuy.anz@latitudefinancial.com",
};

export const SHOPIFY_SESSION_STATUS = {
  APPROVED_ERROR: "approved_error",
  INITIATED: "initiated",
  APPROVED: "approved",
  FAILED: "failed",
  FAILED_ERROR: "failed_error",
  CANCELLED: "cancelled",
  REJECTED: "rejected",
};

// TODO: move to components/ErrorMessage
export const ERROR_TYPE = {
  INVALID_REQUEST: "invalid-request",
};

export const ACTION_TYPE = {
  REFUND: "Refund",
  VOID: "Void",
  CAPTURE: "Capture",
};

export const PAYMENT_OPTIONS = {
  EQUAL: "equal",
  MINIMUM: "minimum",
  FLEXIBLE: "flexible",
  DEFERRED: "deferred",
};

export const THREATMETRIX_CONFIG = {
  SESSION_ID: "TM_SessionID",
  PROFILE_COMPLETE_EVENTNAME: "message",
  ON_MESSAGE: "onmessage",
  PROFILING_DOMAIN: "id.latitudefinancial.com",
};

export const TENDER_TYPE = {
  PWP_CARD: "PWP + Card",
  PWP: "PWP",
  CARD: "Card",
};

export const MERCHANT_GROUP = {
  DEFAULT_AU: "default-au",
  DEFAULT_NZ: "default-nz",
  APPLE_AU: "apple-au",
  APPLE_NZ: "apple-nz",
}

export const APPLE_GROUPS = [
  MERCHANT_GROUP.APPLE_AU,
  MERCHANT_GROUP.APPLE_NZ
];